import { MatchRoute, useNavigate } from "@tanstack/react-location"
import { setTemp, trpc, useBatchId, useDeviceId, setUnit } from "@accubrew/state"

import { Modal } from "../../components"

import { CalendarOverview, Chart, DeviceInfo, DeviceRunOverview, MessagesBoard, NotesOverview } from "./components"
import { DeviceInfoModal } from "./DeviceInfoModal"
import { GaugeCharts } from "./components/GaugeCharts"
import { useEffect } from "react"
import { ConfirmModal } from "../../components"

function Page({ children, title }: { children: React.ReactNode; title?: string }) {
  useEffect(() => {
    document.title = `AccuBrew${title ? ` | ${title}` : ""}`
  }, [title])

  return <>{children}</>
}
export function Home() {
  const navigate = useNavigate()
  const batchId = useBatchId()
  const user = trpc.user.get.useQuery()

  useEffect(() => {
    setTemp(user?.data?.uom)
    setUnit(user?.data?.sgUnit)
  }, [user])

  return (
    <Page title="Dashboard">
      <div className="mx-4 gap-y-3 gap-x-3 grid grid-cols-1 lg:grid-cols-12">
        <div className="col-span-1 lg:col-span-7 2xl:col-span-6 row-span-1">
          <DeviceInfo />
        </div>
        <NoBatchesView />
        {batchId && (
          <>
            <div className="bg-white rounded-md p-3 shadow col-span-1 lg:col-span-7 2xl:col-span-6">
              <DeviceRunOverview batchId={batchId} />
              <GaugeCharts batchId={batchId} />
            </div>
            <div className="col-span-1 lg:col-span-12 2xl:col-span-9">
              <Chart batchId={batchId} />
            </div>
            <div className="order-6 md:order-4 h-full min-h-[350px] col-span-1 lg:col-span-6 2xl:col-start-10 2xl:col-span-3 2xl:row-start-1 2xl:row-span-2">
              <MessagesBoard batchId={batchId} />
            </div>
            <div className="h-full min-h-[350px]  max-h-[450px] col-span-1 lg:col-span-6 2xl:col-start-10 2xl:col-span-3 2xl:row-start-3 2xl:w-full 2xl:aspect-[1.499] 2xl:max-h-none relative overflow-hidden bg-white shadow rounded-md">
              <NotesOverview batchId={batchId} />
            </div>
            <div className="h-full order-4  md:order-6 min-h-[350px] col-span-1 lg:row-start-1 lg:col-start-8 lg:col-span-5 lg:row-span-2 2xl:col-start-7 2xl:col-span-3">
              <CalendarOverview batchId={batchId} />
            </div>
          </>
        )}
      </div>

      <MatchRoute to="probe-info/:id">
        {(match) => {
          return (
            <Modal visible={match !== undefined} onClose={() => navigate({ to: "/dashboard/home" })}>
              <DeviceInfoModal />
            </Modal>
          )
        }}
      </MatchRoute>
     
    </Page>
  )
}

function NoBatchesView() {
  const device = useDeviceId()
  const batchId = useBatchId()

  if (batchId || !device) return null

  return (
    <>
      <div className="bg-white rounded-md p-3 shadow col-span-1 lg:col-span-7 2xl:col-span-6">
        <DeviceRunOverview />
      </div>
      <div className="col-span-1 lg:col-span-12 2xl:col-span-9" />
      <div className="h-full  col-span-1 lg:col-span-6 2xl:col-start-10 2xl:col-span-3 2xl:row-start-1 2xl:row-span-2" />
      <div className="h-full   max-h-[450px] col-span-1 lg:col-span-6 2xl:col-start-10 2xl:col-span-3 2xl:row-start-3 2xl:w-full 2xl:aspect-[1.499] 2xl:max-h-none relative overflow-hidden bg-white rounded-md" />
      <div className="h-full  col-span-1 lg:row-start-1 lg:col-start-8 lg:col-span-5 lg:row-span-2 2xl:col-start-7 2xl:col-span-3" />
    </>
  )
}
