import ReactDOM from "react-dom/client"
import * as Sentry from "@sentry/react"

import { TRPCProvider } from "@accubrew/state"

import { App } from "./App"
import { URL, WS_URL, headers } from "./utils"

Sentry.init({
  dsn: "https://7928fcf3352d42b99d647aaaa05e54a0@o4504595110821888.ingest.sentry.io/4505062634553344",
  // tunnel: "/tunnel", // look into it more.
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
})

try {
  ReactDOM.createRoot(document.getElementById("root")!).render(
    <TRPCProvider headers={headers} url={URL} webSocketUrl={WS_URL}>
      <App />
    </TRPCProvider>,
  )
} catch (error) {}

