// src/EventSubscription.tsx
import { memo, useCallback } from "react";

// src/trpc.tsx
import * as React from "react";
import { createTRPCReact, httpBatchLink, splitLink, createTRPCProxyClient } from "@trpc/react-query";
import { createWSClient, wsLink } from "@trpc/client";
import superjson from "superjson";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
var trpc = createTRPCReact();
function createStaticTrpc({ url, webSocketUrl, headers }) {
  return createTRPCProxyClient({
    transformer: superjson,
    links: [
      splitLink({
        condition: (op) => op.type === "subscription",
        true: wsLink({
          client: createWSClient({
            url: webSocketUrl,
            retryDelayMs: () => 3e3,
            onClose: () => console.log("closed"),
            onOpen: () => console.log("opened")
          })
        }),
        false: httpBatchLink({ url, headers })
      })
    ]
  });
}
function createTrpcClient({ url, webSocketUrl, headers }) {
  const client = trpc.createClient({
    transformer: superjson,
    links: [
      splitLink({
        condition: (op) => op.type === "subscription",
        true: wsLink({
          client: createWSClient({ url: webSocketUrl, retryDelayMs: () => 3e3, onClose: () => console.log("closed"), onOpen: () => console.log("opened") })
        }),
        false: httpBatchLink({ url, headers })
      })
    ]
  });
  return { client };
}
var queryClient = new QueryClient({
  defaultOptions: {
    queries: { retry: 1, retryDelay: 1e3 }
  }
});
function TRPCProvider({ children, url, webSocketUrl, headers }) {
  const [client] = React.useState(() => {
    const { client: client2 } = createTrpcClient({ url, webSocketUrl, headers });
    return client2;
  });
  return /* @__PURE__ */ React.createElement(trpc.Provider, { queryClient, client }, /* @__PURE__ */ React.createElement(QueryClientProvider, { client: queryClient }, children));
}

// src/state.ts
import { create } from "zustand";
var initialState = {
  token: void 0,
  theme: void 0,
  deviceId: void 0,
  batchId: void 0,
  temp: "f",
  unit: "SG",
  isBiometricEnabled: false,
  homeIsLoading: true
};
var Store = {
  // @ts-ignore
  useStore: void 0,
  init: (initState = initialState) => {
    if (Store.useStore === void 0) {
      Store.useStore = create(() => ({ ...initialState, ...initState }));
    } else {
      console.warn("Store already initialized");
    }
    return Store.useStore;
  },
  setState: (state) => {
    Store.useStore.setState(state);
  },
  clear: () => {
    Store.setState((state) => ({ ...initialState, theme: state.theme }));
  },
  onChange: (listener) => {
    return Store.useStore.subscribe(listener);
  }
};

// src/selectors.ts
function selectBatchId(state) {
  return state.batchId;
}
function selectDeviceId(state) {
  return state.deviceId;
}
function selectToken(state) {
  return state.token;
}
function themeSelector(state) {
  return state.theme;
}
function tempSelector(state) {
  return state.temp;
}
function unitSelector(state) {
  return state.unit;
}
function homeIsLoadingSelector(state) {
  return state.homeIsLoading;
}
function biometricSelector(state) {
  return state.isBiometricEnabled;
}

// src/hooks.ts
function useBatchId() {
  return Store.useStore(selectBatchId);
}
function useDeviceId() {
  return Store.useStore(selectDeviceId);
}
function useToken() {
  return Store.useStore(selectToken);
}
function useTheme() {
  return Store.useStore(themeSelector);
}
function useTemp() {
  return Store.useStore(tempSelector);
}
function useUnit() {
  return Store.useStore(unitSelector);
}
function useHomeIsLoading() {
  return Store.useStore(homeIsLoadingSelector);
}
function useBiometric() {
  return Store.useStore(biometricSelector);
}

// src/actions.ts
function setToken(token) {
  Store.setState({ token });
}
function setDeviceId(deviceId) {
  Store.setState({ deviceId });
}
function setTheme(theme) {
  Store.setState({ theme });
}
function setBatchId(batchId) {
  Store.setState({ batchId });
}
function setTemp(temp) {
  Store.setState({ temp });
}
function setUnit(unit) {
  Store.setState({ unit });
}
function setHomeIsLoading(homeIsLoading) {
  Store.setState({ homeIsLoading });
}
function setIsBiometricEnabled(isBiometricEnabled) {
  Store.setState({ isBiometricEnabled });
}
function clearStore() {
  Store.setState((state) => ({
    ...initialState,
    theme: state.theme
    // keep theme preference on logout
  }));
}

// src/EventSubscription.tsx
function useAppendMessageOptimistically() {
  const context = trpc.useUtils();
  return useCallback(
    (data) => {
      context.messageBoard.list.invalidate();
    },
    [context]
  );
}
function EventSub() {
  const temp = useTemp();
  const token = useToken();
  const unit = useUnit();
  const user = trpc.user.get.useQuery(void 0);
  const context = trpc.useUtils();
  trpc.temperatureSwitcherEvent.useSubscription(
    { email: user.data?.email, unit: "f" },
    {
      onStarted() {
        console.log("started listening to temperatureSwitcherEvent events.");
      },
      onError(error) {
        console.log("error listening to temperatureSwitcherEvent events.", error);
      },
      onData(result) {
        console.log("SOCKET RESULT=> Temperature set to = ", result);
        setTemp(result);
      }
    }
  );
  trpc.sgUnitSwitcherEvent.useSubscription(
    { email: user.data?.email, unit: "SG" },
    {
      onStarted() {
        console.log("started listening to sgUnitSwitcherEvent  events.");
      },
      onError(error) {
        console.log("error listening to sgUnitSwitcherEvent  events.", error);
      },
      onData(result) {
        console.log("SOCKET RESULT=> unit set to = ", result);
        setUnit(result);
      }
    }
  );
  trpc.notesList.useSubscription(
    { email: user.data?.email },
    {
      onStarted() {
        console.log("started listening to notesList events.");
      },
      onError(error) {
        console.log("error listening to notesList events.", error);
      },
      onData(result) {
        console.log("notesList SOCKET RESULT=> ", result);
        context.note.list.invalidate({ batchId: result });
      }
    }
  );
  trpc.events.useSubscription(
    { token, email: user.data?.email },
    {
      onStarted() {
        console.log("started listening to events.");
      },
      onError() {
        console.log("error listening to events.");
      },
      onData(result) {
        const { particleId: id, type, data } = result;
        switch (type) {
          case "messageBoard":
            context.messageBoard.list.invalidate({ batchId: data.batchId });
            break;
          case "busy":
            if (context.device.get.getData({ particleId: id })?.particleId === void 0)
              break;
            context.device.get.setData({ particleId: id }, (pData = DEFAULT_DEVICE_STATE) => ({ ...pData, online: true, busy: data }));
            break;
          case "running":
            if (data)
              context.batch.list.invalidate({ probeId: id });
            if (context.device.get.getData({ particleId: id })?.particleId === void 0)
              break;
            context.device.get.setData({ particleId: id }, (pData = DEFAULT_DEVICE_STATE) => {
              return { ...pData, online: true, running: data };
            });
            break;
          case "signaling":
            if (context.device.get.getData({ particleId: id })?.particleId === void 0)
              break;
            context.device.get.setData({ particleId: id }, (pData = DEFAULT_DEVICE_STATE) => {
              return { ...pData, online: true, signaling: data };
            });
            break;
          case "charging":
            console.log({ particleId: id, charging: data });
            if (context.device.get.getData({ particleId: id })?.particleId === void 0)
              break;
            context.device.get.setData({ particleId: id }, (pData = DEFAULT_DEVICE_STATE) => {
              return { ...pData, online: true, charging: data };
            });
            break;
          case "sparkStatus":
            console.log("sparkStatus", data);
            if (context.device.get.getData({ particleId: id })?.particleId === void 0)
              break;
            context.device.get.setData({ particleId: id }, (pData = DEFAULT_DEVICE_STATE) => {
              return { ...pData, online: data };
            });
            break;
          case "reset":
            console.log("reset", data);
            if (context.device.get.getData({ particleId: id })?.particleId === void 0)
              break;
            context.device.get.setData({ particleId: id }, (pData = DEFAULT_DEVICE_STATE) => {
              return { ...pData, online: false, signaling: false };
            });
            break;
          case "wiFiandPower":
            if (context.device.get.getData({ particleId: id })?.particleId === void 0)
              break;
            context.device.get.setData({ particleId: id }, (pData = DEFAULT_DEVICE_STATE) => {
              return { ...pData, online: true, wifi: data[0], power: data[1] };
            });
            break;
          case "chartUpdate":
            if (context.batch.stats.getData({ batchId: result.batchId, allData: true })?.labels !== void 0) {
              context.batch.stats.setData({ batchId: result.batchId, allData: true }, (previoudStats) => {
                const prevSG = previoudStats.datasets.specificGravity;
                const prevPlato = previoudStats.datasets.plato;
                const prevF = previoudStats.datasets.fermentation;
                const prevC = previoudStats.datasets.clarity;
                const prevTC = previoudStats.datasets.temperatureC;
                const prevTF = previoudStats.datasets.temperatureF;
                return {
                  labels: [...previoudStats.labels, data.label],
                  datasets: {
                    specificGravity: { ...prevSG, data: [...prevSG.data, data.specificGravity] },
                    // sG
                    plato: { ...prevPlato, data: [...prevPlato.data, data.plato] },
                    // plato
                    fermentation: { ...prevF, data: [...prevF.data, data.fermentation] },
                    // fermentation
                    clarity: { ...prevC, data: [...prevC.data, data.clarity] },
                    // clarity
                    temperatureC: { ...prevTC, data: [...prevTC.data, data.temperatureC] },
                    // temp
                    temperatureF: { ...prevTF, data: [...prevTF.data, data.temperatureF] }
                    // temp
                  }
                };
              });
            }
            if (context.batch.stats.getData({ batchId: result.batchId })?.labels !== void 0) {
              context.batch.stats.setData({ batchId: result.batchId }, (previoudStats) => {
                const prevSG = previoudStats.datasets.specificGravity;
                const prevPlato = previoudStats.datasets.plato;
                const prevF = previoudStats.datasets.fermentation;
                const prevC = previoudStats.datasets.clarity;
                const prevTC = previoudStats.datasets.temperatureC;
                const prevTF = previoudStats.datasets.temperatureF;
                return {
                  labels: [...previoudStats.labels, data.label],
                  datasets: {
                    specificGravity: { ...prevSG, data: [...prevSG.data, data.specificGravity] },
                    // sG
                    plato: { ...prevPlato, data: [...prevPlato.data, data.plato] },
                    // plato
                    fermentation: { ...prevF, data: [...prevF.data, data.fermentation] },
                    // fermentation
                    clarity: { ...prevC, data: [...prevC.data, data.clarity] },
                    // clarity
                    temperatureC: { ...prevTC, data: [...prevTC.data, data.temperatureC] },
                    // temp
                    temperatureF: { ...prevTF, data: [...prevTF.data, data.temperatureF] }
                    // temp
                  }
                };
              });
            }
            break;
        }
      }
    }
  );
  return null;
}
var DEFAULT_DEVICE_STATE = { charging: false, online: false, power: 0, running: false, wifi: 0, signaling: false };
var EventSubscription = memo(EventSub);

// src/HookConsumer.tsx
function HookConsumer({ children, useHook, selector }) {
  const slice = useHook(selector);
  return children(slice);
}

// src/TRPCQuery.ts
function TRPCQuery(args) {
  let hook = trpc;
  for (const key of args.keys)
    hook = hook[key];
  const hookData = hook.useQuery(args.input ?? void 0, args.options);
  return args.children(hookData);
}

// src/misc.ts
function exclude(entity, keys) {
  for (let key of keys)
    delete entity[key];
  return entity;
}
function round(num, decimalPlaces = 0) {
  const factor = 10 ** decimalPlaces;
  if (decimalPlaces === 0) {
    return Math.round(num);
  } else {
    return Math.round((num + Number.EPSILON) * factor) / factor;
  }
}
function ObjectKeys(obj) {
  return Object.keys(obj);
}
var ROUND = {
  specificGravity: 3,
  fermentation: 3,
  clarity: void 0,
  tempC: 1,
  tempF: 1,
  default: 2
};

// src/StateContainer.tsx
import * as React2 from "react";
function useStateContainer() {
  return React2.useRef(null);
}
function StateContainerP({ initialState: initialState2, children }, ref) {
  const [state, setState] = React2.useState(initialState2);
  React2.useImperativeHandle(ref, () => ({ getState: () => state, setState }), [state]);
  return children(state, setState);
}
var StateContainer = React2.forwardRef(StateContainerP);

// src/StoreConsumer.tsx
import { shallow } from "zustand/shallow";
function StoreConsumer({ children, selector }) {
  const slice = Store.useStore(selector, shallow);
  return children(slice);
}

// src/useBatches.ts
function useBatches(args) {
  const user = trpc.user.get.useQuery();
  return trpc.batch.list.useQuery(
    user.data?.role === "COLLABORATOR" ? void 0 : { probeId: args?.deviceId },
    //
    { enabled: !!args?.deviceId || user.data?.role === "COLLABORATOR" }
  );
}

// src/useDashboardCalendar.ts
import { format } from "date-fns";
import { useQuery } from "@tanstack/react-query";
function useDashboardCalendar(batchId, startDate, endDate, staticTrpc) {
  return useQuery([batchId, startDate, endDate], async () => {
    const { notes, batch } = await staticTrpc.calendar.get.query({ batchId, startDate, endDate });
    return [...notes, batch].reduce((memo2, item) => {
      if (item?.status) {
        if (item.status === "RUNNING") {
          const dateString2 = format(item.createdAt, "dd-MM-yyyy");
          if (!Array.isArray(memo2[dateString2]))
            memo2[dateString2] = [];
          memo2[dateString2].push(item);
        }
        if (item.status === "STOPPED") {
          const dateString2 = format(item.updatedAt, "dd-MM-yyyy");
          if (!Array.isArray(memo2[dateString2]))
            memo2[dateString2] = [];
          memo2[dateString2].push(item);
        }
      }
      const dateString = format(item.type === "Reminder" ? item.reminderDate : item.createdAt, "dd-MM-yyyy");
      if (!Array.isArray(memo2[dateString]))
        memo2[dateString] = [];
      memo2[dateString].push(item);
      return memo2;
    }, {});
  });
}
export {
  DEFAULT_DEVICE_STATE,
  EventSubscription,
  HookConsumer,
  ObjectKeys,
  ROUND,
  StateContainer,
  Store,
  StoreConsumer,
  TRPCProvider,
  TRPCQuery,
  biometricSelector,
  clearStore,
  createStaticTrpc,
  createTrpcClient,
  exclude,
  homeIsLoadingSelector,
  initialState,
  queryClient,
  round,
  selectBatchId,
  selectDeviceId,
  selectToken,
  setBatchId,
  setDeviceId,
  setHomeIsLoading,
  setIsBiometricEnabled,
  setTemp,
  setTheme,
  setToken,
  setUnit,
  tempSelector,
  themeSelector,
  trpc,
  unitSelector,
  useAppendMessageOptimistically,
  useBatchId,
  useBatches,
  useBiometric,
  useDashboardCalendar,
  useDeviceId,
  useHomeIsLoading,
  useStateContainer,
  useTemp,
  useTheme,
  useToken,
  useUnit
};
