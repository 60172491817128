import { motion } from "framer-motion"
import { trpc, useDeviceId } from "@accubrew/state"
import { useSelectedDevcieState } from "../../../hooks"
import { svg } from "../../../assets"

const spring = { type: "spring", stiffness: 700, damping: 30 }
type SignalProps = {
  screen?: string
}
const SignalingButton: React.FC<SignalProps> = ({ screen }) => {
  const user = trpc.user.get.useQuery()
  const deviceId = useDeviceId()
  const device = useSelectedDevcieState()
  const signalDevice = trpc.device.signal.useMutation()

  const isABleToSignalDevice = user.data?.role === "OWNER" || user.data?.role === "PRODUCTION" || user.data?.role === "MANAGER"

  function handleClick() {
    signalDevice.mutate(
      { id: deviceId!, signal: !device.data?.signaling },
      // {
      //   onSuccess() {
      //     device.refetch()
      //   },
      // },
    )
  }

  const LOADING = (signalDevice.isLoading || device.isLoading) && deviceId !== undefined
  const DISABLED = !deviceId || device.isFetching || device.status !== "success" || !device.data?.online || device.data.busy
  const signaling = device.data?.signaling ?? false

  return (
    <div className="flex items-center gap-x-3">
      <span className="text-[#342F20] text-[16px] font-[400] whitespace-nowrap">Signal LED</span>
      <motion.button
        className="w-[50px] h-[24px] p-1 rounded-[12px]"
        onClick={handleClick}
        animate={{ backgroundColor: signaling ? "#F88D2A" : "#EAEAEA" }}
        disabled={!isABleToSignalDevice || DISABLED}
      >
        <motion.div
          className={`w-4 h-4 rounded-full bg-white ${signaling ? "ml-auto" : ""}`}
          layout
          animate={{ backgroundColor: !signaling ? "#F88D2A" : "#FFF" }}
          transition={spring}
        />
      </motion.button>
      {screen && <svg.Loading className={`w-4 h-4 ${LOADING ? "" : "opacity-0"}`} />}
    </div>
  )
}
export default SignalingButton
