import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
  LineController,
  BarController,
} from "chart.js"
import { useState } from "react"
import { Line } from "react-chartjs-2"
import { round, ObjectKeys, calculateROC, sgToPlato } from "@accubrew/utils"
import { trpc, useBatches, useDeviceId, useTemp, useUnit, StateContainer, useStateContainer } from "@accubrew/state"
import { format } from "date-fns"
import { ConfirmModal } from "../../../components"
import { svg } from "../../../assets"
import { DeviceDropdown } from "../../dashboard/components"
import { BatchDropdown } from "../../../components"
import { FiDownload } from "react-icons/fi"
import { BatchesDropdown } from "./BatchesDropdown"

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler, Legend, LineController, BarController)

const PERIOS_TO_NUMBER_OF_STATS = {
  "4h": 18,
  "8h": 36,
  "12h": 54,
  "24h": 108,
  all: undefined,
}

export default function Charts({
  batchId,
  batchname,
  selectedBatchId,
  setSelectedBatchId,
}: {
  batchId: any
  batchname: any
  selectedBatchId: any
  setSelectedBatchId: any
}) {
  const temp = useTemp()
  const unit = useUnit()
  const user = trpc.user.get.useQuery()
  const deleteChartPoint = trpc.batch.deleteChartPoint.useMutation()
  type DataDeleteType = { visible: boolean; fileName?: any; readingTime: any }
  const dataDeleteConfirmRef = useStateContainer<DataDeleteType>()
  const MAP_DATASET_TO_INDEX =
    temp === "c" && unit === "SG"
      ? (["specificGravity", "fermentation", "clarity", "temperatureC"] as const)
      : temp === "f" && unit === "SG"
      ? (["specificGravity", "fermentation", "clarity", "temperatureF"] as const)
      : temp === "f" && unit === "Plato"
      ? (["plato", "fermentation", "clarity", "temperatureF"] as const)
      : (["plato", "fermentation", "clarity", "temperatureC"] as const)

  const deviceId = useDeviceId()!
  const batches = useBatches({ deviceId })
  // const [selectedBatchId, setSelectedBatchId] = useState<string | null | undefined>(undefined)
  const comparebatchname = batches?.data?.batches?.filter((batch: any) => batch?.id === selectedBatchId)! || []

  const [period, setPeriod] = useState<keyof typeof PERIOS_TO_NUMBER_OF_STATS>("all")
  const [activeState, setActiveState] = useState<0 | 1 | 2 | 3>(0)
  const stats: any = trpc.batch.stats.useQuery({ batchId, ...(period === "all" ? { allData: true } : {}) }, { enabled: !!batchId })

  const overlayStats: any = trpc.batch.stats.useQuery(
    { batchId: selectedBatchId, ...(period === "all" ? { allData: true } : {}) },
    { enabled: !!selectedBatchId },
  )

  const exportCsv: any = trpc.batch.exportBatchStatsCsv.useMutation()

  //  needs a refactor
  const labels = period === "all" ? stats.data?.labels ?? [] : stats.data?.labels.slice(-PERIOS_TO_NUMBER_OF_STATS[period]) ?? []
  // const activeData = stats.data?.datasets?.[MAP_DATASET_TO_INDEX[activeState]]
  // const data =
  //   period === "all"
  //     ? stats.data?.datasets?.[MAP_DATASET_TO_INDEX[activeState]].data ?? []
  //     : stats.data?.datasets?.[MAP_DATASET_TO_INDEX[activeState]].data?.slice(-PERIOS_TO_NUMBER_OF_STATS[period]) ?? []
  function convertDataToPlato(data: any) {
    const platoData = data.map((sg: any) => sgToPlato(sg))
    return platoData
  }
  const arrdata = stats.data?.datasets?.specificGravity?.data
  const activeData = unit === "Plato" ? convertDataToPlato(arrdata) : arrdata

  if (stats && stats.data && stats.data.datasets && stats.data.datasets.specificGravity) {
    stats.data.datasets.specificGravity.data = activeData
  } else {
    console.error("Dataset structure not found")
  }

  let result: { label: string; data: any }[] = []

  if (stats?.data?.datasets) {
    result = Object.keys(MAP_DATASET_TO_INDEX).map((key, index) => {
      return {
        label: key,
        data: stats?.data?.datasets[MAP_DATASET_TO_INDEX[index]]?.data.map((item: any) => item),
      }
    })
  }
  const handleCsvDownload = () => {
    exportCsv.mutate(
      {
        batchId: batchId ?? "",
      },
      {
        onSuccess(data: any) {
          const link: any = document.createElement("a")
          link.download = data.url
          link.href = data.url
          link.click()
        },
        onError(error: any) {
          console.log("Error export csv", error)
        },
      },
    )
  }

  return (
    <div className="bg-white rounded-2xl py-4 px-5 shadow-lg relative w-full lg:w-[65%]">
      <div className="mb-5 flex justify-between items-center">
        <h3 className="text-textPrimary font-extrabold text-[24px]">Chart</h3>
        <div
          className={`border-2 px-[6px] py-[3px] space-x-[5px] rounded-[6px] hidden md:block${
            stats.data?.datasets[MAP_DATASET_TO_INDEX[activeState]].label ? "" : "opacity-0 pointer-events-none"
          }`}
        >
          {ObjectKeys(PERIOS_TO_NUMBER_OF_STATS).map((item) => (
            <button
              key={item}
              className={`text-[14px] font-extrabold text-textTertiary px-[10px] py-[2px] rounded-[6px] ${
                item === period ? "bg-darkOrangeDark text-white" : ""
              }`}
              onClick={() => setPeriod(item)}
            >
              {item}
            </button>
          ))}
        </div>

        <div className="flex items-center">
          {!["PRODUCTION", "COLLABORATOR"].includes(user.data!.role) && (
            <button
              className="text-orangeDark w-fit px-2 h-[35px] bg-orangeLight mr-[15px] rounded-[6px] flex justify-center items-center space-x-1"
              onClick={handleCsvDownload}
            >
              {exportCsv.isLoading ? (
                <svg.Loading className="mx-auto w-6 h-6" />
              ) : (
                <>
                  <p className="text-sm">Export CSV</p>
                  <FiDownload className="text-orangeDark" />
                </>
              )}
            </button>
          )}
          {/* <button className="w-[35px] h-[35px] bg-btnTertiary rounded-[6px] flex justify-center items-center">
            <svg.Maximize2 />
          </button> */}
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-2 max-w-md lg:max-w-3xl w-full">
        <DeviceDropdown className="lg:col-span-1 col-span-1 bg-red-100" setSelectedBatchId={setSelectedBatchId} />
        <BatchesDropdown
          hideWhenDisabled
          loading={batches.isLoading}
          batches={batches.data?.batches ?? []}
          disabled={!deviceId}
          selectedBatchId={selectedBatchId}
          onBatchIdChange={setSelectedBatchId}
          highlightRunningBatch
        />
      </div>

      <div
        className={`flex flex-col gap-3 md:hidden w-full my-3 ${
          stats.data?.datasets[MAP_DATASET_TO_INDEX[activeState]].label ? "" : "opacity-0 pointer-events-none"
        }`}
      >
        <div className="inline-flex justify-between flex-wrap rounded-md shadow-sm border border-gray-200 p-1 space-x-2 w-full md:w-[50%]">
          {ObjectKeys(PERIOS_TO_NUMBER_OF_STATS).map((item) => (
            <button
              key={item}
              className={`text-[14px] font-extrabold text-textTertiary px-[10px] py-[2px] rounded-[6px] ${item === period ? "bg-orange text-white" : ""}`}
              onClick={() => setPeriod(item)}
            >
              {item}
            </button>
          ))}
        </div>
      </div>

      <div style={{ aspectRatio: 3 }}>
        {stats.data?.labels
          ? result?.map((item: any, index) => {
              return (
                <div key={stats.data?.datasets?.[MAP_DATASET_TO_INDEX[index]]?.label } className="mt-10 space-y-2">
                  <div className="flex flex-col justify-between items-center">
                    <span className="font-extrabold text-[18px] text-textPrimary w-full">
                      {stats.data?.datasets?.[MAP_DATASET_TO_INDEX[index]]?.label ?? "Fermentation.."}
                    </span>
                    <div className="flex gap-x-4 items-center justify-between md:justify-start w-full my-3">
                      <p className="text-textTertiary font-normal text-[16px] md:whitespace-nowrap">
                        Last reading: <span className="text-textPrimary font-bold"> {item?.label === "0" ? round(item?.data[item?.data?.length - 1] ?? 0, 3) : round(item?.data[item?.data?.length - 1] ?? 0, 2)}</span>
                      </p>
                      <p
                        className="font-normal text-[16px]  px-[10px] py-[4px] rounded md:whitespace-nowrap"
                        style={{
                          color: stats.data?.datasets?.[MAP_DATASET_TO_INDEX[index]]?.textColor,
                          backgroundColor: stats.data?.datasets?.[MAP_DATASET_TO_INDEX[index]]?.backgroundColor,
                        }}
                      >
                        Change: {round(calculateROC(item?.data), 2)}%
                      </p>
                    </div>
                  </div>

                  <div className="flex justify-center items-center">
                    {batchname?.length > 0 && (
                      <>
                        {" "}
                        <span className="mr-2 font-semibold text-textTertiary">{batchname[0].name}</span>
                        <div
                          style={{
                            backgroundColor: stats.data?.datasets?.[MAP_DATASET_TO_INDEX[index]]?.backgroundColor,
                            border: `3px solid ${stats.data?.datasets?.[MAP_DATASET_TO_INDEX[index]]?.textColor}`,
                            width: "40px",
                            height: "20px",
                          }}
                        ></div>
                      </>
                    )}
                    {comparebatchname?.length > 0 && (
                      <>
                        {" "}
                        <span className="ml-5 mr-2 font-semibold text-textTertiary">{comparebatchname[0].name}</span>
                        <div style={{ backgroundColor: "#dbeaff", width: "40px", height: "20px", border: "3px solid rgb(53, 162, 235)" }}></div>
                      </>
                    )}
                  </div>
                  <Line
                    data={{
                      labels,
                      datasets: [
                        {
                          ...stats.data?.datasets?.[MAP_DATASET_TO_INDEX[index]],
                          backgroundColor: comparebatchname.length > 0 ? "transparent" : stats.data?.datasets?.[MAP_DATASET_TO_INDEX[index]]?.backgroundColor,
                        },
                        {
                          ...overlayStats.data?.datasets?.[MAP_DATASET_TO_INDEX[index]],
                          backgroundColor: "transparent",
                          borderColor: "rgb(53, 162, 235)",
                        },
                      ],
                    }}
                    options={{
                      aspectRatio: 3,
                      responsive: true,
                      scales: {
                        y: { grid: { display: true } },
                        x: {
                          grid: { display: false },
                          labels: labels.map((label: any) => format(new Date(label), "MM/dd HH:mm")),
                          // max: 15,
                        },
                      },
                      onClick: (event, elements, chart) => {
                        if (elements?.length > 0) {
                          const clickedElement = elements[0]
                          const index = clickedElement?.index

                          dataDeleteConfirmRef.current?.setState({
                            visible: true,
                            fileName: stats?.data?.fileName[index],
                            readingTime: stats?.data?.readingTime[index],
                          })
                        }
                        console.log({ event, elements, chart })
                      },
                      plugins: {
                        legend: { display: false, position: "top" as const, fullSize: true },
                        title: { display: false },
                      },
                    }}
                  />
                </div>
              )
            })
          : null}
      </div>
      <StateContainer initialState={{ visible: false, fileName: null, readingTime: null }} ref={dataDeleteConfirmRef}>
        {(state: any, setState) => (
          <ConfirmModal
            visible={state.visible}
            title="Are you sure you want to delete this data point?"
            description="This action cannot be undone."
            onConfirm={() => {
              deleteChartPoint.mutate(
                { batchId, fileName: state?.fileName, readingTime: state.readingTime },
                {
                  onSuccess(data: any) {
                    stats.refetch()
                    // alert("done")
                  },
                  onError(error: any) {
                    console.log("Error export csv", error)
                  },
                },
              )
              setState({ visible: false })
            }}
            hide={() => setState({ visible: false })}
            onCancel={() => setState({ visible: false })}
            // loading={deleteMultipleBatch.isLoading || batches.isFetching}
          />
        )}
      </StateContainer>
    </div>
  )
}
