import React, { useState } from "react"
import { Link, Outlet, useLocation } from "@tanstack/react-location"
import { create } from "zustand"
import { UserIcon } from "@heroicons/react/24/solid"

import { Store, queryClient, trpc } from "@accubrew/state"

import { images, svg } from "../assets"
import { ClaimDeviceButton } from "./ClaimingDeviceButton"
import { FiPhoneCall, FiUser } from "react-icons/fi"
import { ModalContactUs } from "../pages/contact_us"
import { MdKeyboardArrowDown } from "react-icons/md"
import Drawer from "./Drawer"
const TABBAR_ITEMS = [
  { icon: svg.Dashboard, name: "Dashboard", path: "/dashboard/home", tabbarVisible: true },
  { icon: svg.Bookmark, name: "BrewBook", path: "/dashboard/brewbook", tabbarVisible: true },
  { icon: svg.Book, name: "Batches", path: "/dashboard/batches", tabbarVisible: true },
  // { icon: svg.Calendar, name: "Calendar", path: "/dashboard/calendar", tabbarVisible: true },
  // { icon: svg.MessageBox, name: "Message Board ", path: "/dashboard/message-board", tabbarVisible: true },
  { icon: svg.Layout, name: "About AccuBrew", path: "/dashboard/accubrew/about", tabbarVisible: true },
]

const useTabbarItems = create(() => [
  ...TABBAR_ITEMS,
  { icon: null, name: "Notifications", path: "/dashboard/notifications", tabbarVisible: true },
  { icon: null, name: "Device Info", path: "/dashboard/home/probe-info", tabbarVisible: true },
  { icon: null, name: "Profile", path: "/dashboard/settings/profile", tabbarVisible: true },
  { icon: null, name: "Account Management", path: "/dashboard/settings/accounts", tabbarVisible: true },
  { icon: null, name: "Device Management", path: "/dashboard/settings/devices", tabbarVisible: true },
  { icon: null, name: "Pricing", path: "/dashboard/accubrew/pricing", tabbarVisible: true },
  { icon: null, name: "Probe Troubleshooting", path: "/dashboard/accubrew/probe-troubleshooting", tabbarVisible: true },
  { icon: null, name: "Batch History", path: "/dashboard/batches/batch-history", tabbarVisible: true },
])

export function DashboardLayout() {
  return (
    <>
      <Wrapper>
        <Header />
        <Outlet />
        <NavigationBar />
      </Wrapper>

      <footer className=" text-[#858279] bg-gray-50 w-100 hidden md:block lg:block" style={{ width: "100%" }}>
        <div className="px-5 pb-1 grid  grid-cols-1 md:grid-cols-3 lg:grid-cols-3 text-[#858279] mt-auto">
          <div className="grid md:grid-cols-3 grid-cols-2 lg:grid-cols-3 lg:col-span-1 md:col-span-2">
            <a href="https://accubrew.io/" className="mb-5 sm:mb-0">
              {new Date().getFullYear()} accubrew.io
            </a>
            <a href="https://accubrew.io/privacy-policy/" className="md:ml-1">
              Privacy Policy
            </a>
            <a href="" className="">
              Terms of use
            </a>
          </div>
          <div className="flex items-center gap-6 text-[#858279]  background-red lg:ml-auto md:ml-auto lg:col-span-2 md:col-span-1">
            <a href="https://www.instagram.com/accubrew/" className="footerIcon">
              <svg.Instagram />
            </a>
            <a href="https://twitter.com/GPAccuBrew" className="footerIcon">
              <svg.Twitter />
            </a>
            <a href="https://www.youtube.com/@accubrew650" className="footerIcon">
              <svg.Youtube />
            </a>
          </div>
        </div>
      </footer>
    </>
  )
}

function Wrapper({ children }: { children: React.ReactNode }) {
  const pathname = useLocation().current.pathname
  const item = useTabbarItems((items) => items.find((item) => pathname.includes(item.path)))

  return (
    <div className={item?.tabbarVisible ? "pb-20 md:pb-3 md:pl-[70px] lg:pl-[100px] lg:pb-16 bg-gray-50 min-h-screen" : "md:pb-3"}>
      {/*  */}
      {children}
    </div>
  )
}

function NavigationBar() {
  const [isContact, setIsContact] = useState(false)
  const pathname = useLocation().current.pathname
  const item = useTabbarItems((items) => items.find((item) => pathname.includes(item.path)))

  if (!item?.tabbarVisible) return null

  return (
    <div className="h-[70px] fixed bottom-0 left-0 right-0 md:w-[70px] md:right-auto md:left-0 md:top-0 md:h-auto lg:w-[100px]">
      <div className="flex bg-white h-full md:flex-col max-h-[550px] shadow-lg rounded-tr-md">
        <Link to="/dashboard/home" className="hidden md:block cursor-pointer">
          <img src={images.logo} className="h-[64px] mt-4 mb-10 mx-auto" />
        </Link>
        {TABBAR_ITEMS.map((item) => (
          <div
            className={`flex-1 flex justify-center items-center ${
              pathname.includes(item.path) &&
              "bg-darkOrangeDark rounded-xl rounded-tr-none rounded-tl-none md:rounded-tr-lg md:rounded-tl-lg md:rounded-lg  mx-2 "
            } my-2`}
            key={item.path}
          >
            <Link to={item.path} key={item.path} className="w-full h-full flex justify-center items-center py-0">
              <item.icon stroke={pathname.includes(item.path) ? "#fff" : "#B6B4AF"} />
            </Link>
          </div>
        ))}
        {/* <div className="h-8" /> */}
        <button
          className={`flex justify-center items-center m-2 py-4
         rounded-lg gap-3 bg-transparent lg:bg-[#FEEEDF] text-[#F88D2A]`}
          onClick={() => {
            setIsContact(true)
          }}
        >
          <FiPhoneCall className="h-5 w-5" />
        </button>
      </div>
      <ModalContactUs isContact={isContact} setIsContact={setIsContact} />
    </div>
  )
}

function Title() {
  const location = useLocation()
  // const name = useTabbarItems((items) => items.find((item) => location.current.pathname.includes(item.path))?.name)
  const name = useTabbarItems((items) => items.find((item) => location.current.pathname === item.path)?.name)

  return <>{name ?? ""}</>
}

function Header() {
  const user = trpc.user.get.useQuery()
  const [isOpen, setIsOpen] = useState(false)
  const allowedRoles = ["OWNER", "MANAGER", "PRODUCTION"]
  const isAllowed = allowedRoles.includes(user.data!.role)
  const handleToggle = () => {
    setIsOpen(!isOpen)
  }

  const handleOptionClick = () => {
    setIsOpen(false) // Close the dropdown after clicking an option
  }
  function handleLogout() {
    Store.clear()
    requestAnimationFrame(() => queryClient.clear())
  }

  return (
    <>
      <div className="navbar bg-gray-50">
        <div className="flex-1">
          <Link to="/dashboard/home" className="sm:hidden cursor-pointer">
            <img src={images.logo} className="h-[49px] mt-4 mx-5 mr-auto" />
          </Link>
          <h2 className="hidden sm:block sm:ml-4 text-[32px] font-[800] text-[#342F20]">
            <Title />
          </h2>
        </div>
        <div className="flex-none">
          {/* <ClaimDeviceButton /> */}
          <span className="hidden text-[14px] font-[800] md:inline-block mr-4">Hi, {user?.data?.firstName}</span>
          <div className="dropdown dropdown-end z-20 ">
            <label tabIndex={0} className="btn btn-ghost hover:bg-transparent" onClick={handleToggle}>
              {/* <UserIcon width={20} /> */}
              <div className="flex items-center gap-3">
                <span className="bg-[#EDEDED] p-3 rounded-full">
                  <FiUser className="w-5 h-5" />
                </span>
                <MdKeyboardArrowDown className="w-5 h-5" />
              </div>
            </label>
            {isOpen && (
              <>
                <ul tabIndex={0} className="hidden lg:block menu menu-sm dropdown-content mt-3 p-2 bg-white rounded-box w-52 shadow-lg z-20">
                  <li>
                    <Link to="/dashboard/settings/profile" onClick={handleOptionClick}>
                      Personal Info
                    </Link>
                  </li>
                  {isAllowed && (
                    <>
                      <li>
                        <Link to="/dashboard/settings/accounts" onClick={handleOptionClick}>
                          Account Management
                        </Link>
                      </li>
                      <li>
                        <Link to="/dashboard/settings/devices" onClick={handleOptionClick}>
                          Probe Management
                        </Link>
                      </li>
                    </>
                  )}
                  <li>
                    <button className="text-error2" onClick={handleLogout}>
                      Logout
                    </button>
                  </li>
                </ul>
                <Drawer isOpen={isOpen} setIsOpen={setIsOpen} />
              </>
            )}
          </div>
        </div>
      </div>
      <h2 className="ml-5 sm:hidden text-[32px] font-[800] mb-[20px] text-[#342F20]">
        <Title />
      </h2>
      <hr className="mx-4 mb-5"></hr>
    </>
  )
}
