import { Link, useNavigate } from "@tanstack/react-location"
import { MdAreaChart } from "react-icons/md"

import { setBatchId, trpc, useBatches, useDeviceId, useHomeIsLoading } from "@accubrew/state"
import { RunButton, RunButtonIndicator } from "./RunButton"
import { useSelectedDevcieState } from "../../../hooks"
export function DeviceRunOverview({ batchId }: { batchId?: string }) {
  const deviceId = useDeviceId()
  const user = trpc.user.get.useQuery()
  const batches = useBatches({ deviceId })
  const batch = batches.data?.batches.find((b: any) => b.id === batchId)
  const state = useSelectedDevcieState()
  const homeLoading = useHomeIsLoading() ?? false
  const navigate = useNavigate()

  return (
    <div className="flex gap-x-3 mb-4">
      {user.data?.role !== "COLLABORATOR" && (
        <div className="font-800 text-xl flex flex-1 items-center border px-4 border-border/50 rounded-md">
          <span>{batch?.name}</span>
        </div>
      )}
      <div className="flex-1 flex flex-row">
        {user.data?.role === "COLLABORATOR" ? (
          <RunButtonIndicator disabled loading={batches.isLoading} running={state?.data?.running} className="px-3.5 mr-4 flex-1" />
        ) : (
          <RunButton className="px-3.5 mr-4 flex-1" loading={batches.isLoading} running={state?.data?.running} />
        )}
        <button
          disabled={homeLoading}
          onClick={() => {
            navigate({ to: "/dashboard/batches/batch-history" }), setBatchId(batch?.id)
          }}
          className={`px-3.5 bg-[#F88D2A] text-white ${!homeLoading ? "" : "opacity-40"} rounded items-center flex h-11 flex-1`}
        >
          <MdAreaChart className="mx-auto" size={24} />
        </button>
      </div>
    </div>
  )
}
