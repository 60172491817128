import { useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import { cToF, fToC, sgToPlato, platoToSg } from "@accubrew/utils"
import { trpc, useBatchId, useBatches, useDeviceId, useTemp, useUnit } from "@accubrew/state"
import { sgThresholdSchema, tempThresholdsSchema } from "@accubrew/validation"
import { svg } from "../../../assets"

export function ThresholdsForm({ type, hide }: { type: "sg" | "temp"; hide: () => void }) {
  const context = trpc.useContext()
  const setThresholds = trpc.batch.setThresholds.useMutation()
  const batchId = useBatchId()!
  const deviceId = useDeviceId()!
  const batches = useBatches({ deviceId })
  const batch = batches.data?.batches.find((b: any) => b.id === batchId)
  const preferredTemp = useTemp()
  const preferredUnit = useUnit()
  
  const { register, formState, handleSubmit } = useForm({
    resolver: zodResolver(type === "sg" ? sgThresholdSchema : tempThresholdsSchema),
    defaultValues:
      type === "sg"
        ? {
            sgMin:
              preferredUnit === "Plato"
                ? Number.isInteger(sgToPlato(batch?.thresholdSgMin ?? 0))
                  ? sgToPlato(batch?.thresholdSgMin ?? 0).toString()
                  : sgToPlato(batch?.thresholdSgMin ?? 0)
                      .toFixed(1)
                      .toString()
                : batch?.thresholdSgMin?.toFixed(3).toString(),
          }
        : {
            tmpMin:
              preferredTemp === "f"
                ? Number.isInteger(cToF(batch?.thresholdTempMin ?? 0))
                  ? cToF(batch?.thresholdTempMin ?? 0).toString()
                  : cToF(batch?.thresholdTempMin ?? 0)
                      .toFixed(2)
                      .toString()
                : Number.isInteger(batch?.thresholdTempMin)
                ? batch?.thresholdTempMin?.toString()
                : batch?.thresholdTempMin?.toFixed(2)?.toString() ?? "",
            tmpMax:
              preferredTemp === "f"
                ? Number.isInteger(cToF(batch?.thresholdTempMax ?? 0))
                  ? cToF(batch?.thresholdTempMax ?? 0).toString()
                  : cToF(batch?.thresholdTempMax ?? 0)
                      .toFixed(2)
                      .toString()
                : Number.isInteger(batch?.thresholdTempMax)
                ? batch?.thresholdTempMax?.toString()
                : batch?.thresholdTempMax?.toFixed(2)?.toString() ?? "",
          },
  })

  const { isValid } = formState

  const handleSaveChanges = handleSubmit((data) => {
    setThresholds.mutate(
      type === "sg"
        ? { sgMin: preferredUnit === "Plato" ? platoToSg(parseFloat(data.sgMin!)) : parseFloat(data.sgMin!), type: "sg", batchId }
        : {
            tmpMin: preferredTemp === "f" ? fToC(parseInt(data.tmpMin!, 10)) : parseInt(data.tmpMin!, 10),
            tmpMax: preferredTemp === "f" ? fToC(parseInt(data.tmpMax!, 10)) : parseInt(data.tmpMax!, 10),
            type: "temp",
            batchId,
          },
      {
        onSuccess() {
          context.batch.list.invalidate()
          context.batch.get.invalidate({ batchId })
          hide()
        },
      },
    )
  })

  return (
    <>
      <h2 className="px-4 pt-4 pb-2 text-lg font-bold">{type === "temp" ? (preferredTemp === "c" ? "Temperature °C" : "Temperature °F") : preferredUnit === "SG" ? "Density SG" : "Density °P"}</h2>
      

      {type === "sg" && (
        <div className="my-4 px-4 items-center gap-4 flex">
          <label htmlFor="" className="mr-4">
            Min
          </label>
          <input {...register("sgMin")} className="border p-2 w-16 rounded" onFocus={(e) => e.target.select()} />
        </div>
      )}
      {type === "temp" && (
        <>
          <div className="my-4 px-4 items-center gap-4 flex">
            <div className="flex items-center">
              <label htmlFor="" className="mr-4">
                Min
              </label>
              <input {...register("tmpMin")} className="border p-2 w-16 rounded" onFocus={(e) => e.target.select()} />
            </div>
            <div className="flex items-center">
              <label htmlFor="" className="mr-4">
                Max
              </label>
              <input {...register("tmpMax")} className="border p-2 w-16 rounded" onFocus={(e) => e.target.select()} />
            </div>
          </div>
        </>
      )}

      <div className="flex gap-x-3 px-4 mb-4">
        <div className="flex-[2] hidden sm:block" />
        <button className="flex-1 bg-slate-300 py-2 rounded-md font-semibold" onClick={hide}>
          Cancel
        </button>
        <button
          className={`flex-1 py-2 bg-[#F88D2A] rounded-md font-semibold text-white relative ${isValid ? "" : "opacity-50"}`}
          disabled={!isValid}
          onClick={handleSaveChanges}
        >
          {setThresholds.isLoading ? (
            <div className="absolute inset-0 flex justify-center items-center">
              <svg.Loading color="white" className="w-5 h-5" />
            </div>
          ) : null}
          <span className={setThresholds.isLoading ? "opacity-0" : ""}>Save Changes</span>
        </button>
      </div>
    </>
  )
}
